import { Marker, Tooltip } from 'react-leaflet'
import React from 'react'

import { Block, Flex, Text } from 'components/common'
import Icon from 'components/icon'
import { RESOURCE_ICON_MAP } from './constants'

interface Coordinates {
  lat: number
  lng: number
}

interface AuditTemporaryMarkerLayerProps {
  coordinates: Coordinates[] | Coordinates
  draggable: boolean
  icon: string
  onDragEnd: () => void
  onSet: () => void
  resource: string
  score: string
  targetServiceLevel?: string
  title: string
}

export default function AuditTemporaryMarkerLayer(
  props: AuditTemporaryMarkerLayerProps
): JSX.Element {
  const {
    coordinates,
    draggable,
    icon,
    onDragEnd: handleDragEnd,
    onSet: handleSet,
    resource,
    score,
    targetServiceLevel = null,
    title = 'Unknown',
  } = props

  const auditHasTarget = !!targetServiceLevel
  const isBelowTarget = targetServiceLevel && targetServiceLevel === 'below'

  const markerIcon = icon
    ? RESOURCE_ICON_MAP[icon]
    : RESOURCE_ICON_MAP[resource]

  if (!coordinates || !markerIcon) return null

  const markerScore = score || ''

  return (
    <Marker
      draggable={draggable}
      icon={markerIcon}
      onClick={handleSet}
      onDragEnd={handleDragEnd}
      pane="popupPane"
      position={coordinates}
      zIndexOffset={1000}
    >
      <Tooltip direction="top" offset={[0, -15]} pane="popupPane" permanent>
        <Block fontWeight={500}>
          {auditHasTarget ? (
            <Flex flexDirection="row">
              {`${title} -`}
              &nbsp;
              <Icon
                name={isBelowTarget ? 'circle-cross-bold' : 'circle-check-bold'}
                theme={{
                  fontSize: 12,
                  color: isBelowTarget ? '#FB1607' : '#347D39',
                }}
              />
              <Text inline color={isBelowTarget ? '#FB1607' : '#347D39'}>
                &nbsp;
                {`${markerScore}`}
              </Text>
            </Flex>
          ) : (
            `${title} - ${markerScore}`
          )}
        </Block>
      </Tooltip>
    </Marker>
  )
}
