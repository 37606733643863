/**
 * Component which acts as a base to all standard input types (text,
 * select, number etc) allowing us to share common functionality such
 * as Error handling, labels, tooltips etc.
 */

import { compose, pure, onlyUpdateForPropTypes } from 'recompose'
import PropTypes from 'prop-types'
import Radium from 'radium'
import React, { Component } from 'react'

import { Block } from 'components/common'
import { InputGroup, InputLabel } from 'components/form'
import Anchor from 'components/anchor'
import Icon from 'components/icon'
import styles from './styles'

export default ComposedComponent => {
  class FormInput extends Component {
    static propTypes = {
      required: PropTypes.bool,
      id: PropTypes.string,
      name: PropTypes.string,
      placeholder: PropTypes.string,
      label: PropTypes.string,
      small: PropTypes.bool,
      error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
      tooltip: PropTypes.array,
      readOnly: PropTypes.bool,
      disabled: PropTypes.bool,
      warningMessage: PropTypes.string,
    }

    handleKeyPress = event => {
      const { onEnter } = this.props

      if (event.key === 'Enter' && onEnter) {
        return onEnter(event)
      }
    }

    render() {
      const {
        alignTop,
        allowAllDates,
        autoComplete,
        autoFocus,
        capitalize,
        checked,
        children,
        className,
        clearable,
        customInputStyles,
        dataTestId,
        dateFormat,
        defaultValue,
        description,
        disabled,
        disabledDays,
        disablePastDates,
        disableSelectedList,
        disableTimepicker,
        error,
        getRejectedZones,
        hideError,
        hideLabel,
        hideWarn,
        id,
        ignoreTimezone,
        indeterminate,
        infiniteScrolling,
        isLoading,
        isRowLoaded,
        isValidNewOption,
        label,
        listRowCount,
        loadMoreRows,
        location,
        mapTargetMode,
        maxDate,
        minDate,
        multi,
        multiline,
        name,
        noMargin,
        numberOfMonths,
        onBlur,
        onChange,
        onSearch,
        onRemove,
        options,
        overrideChecked,
        placeholder,
        readOnly,
        refreshing,
        required,
        resource,
        showRemoveItems,
        showSelectAll,
        selectAllLimit,
        shouldSort = true,
        showErrorOnUnTouched,
        showPlusOneSign,
        showRedBorder,
        showTimePickerButton,
        showWarnOnUnTouched,
        forceRerender,
        simpleValue,
        small,
        style,
        timeFormat,
        timezone,
        tooltip,
        totalRowCount,
        touched,
        type,
        value,
        values,
        warning,
        warningMessage,
        // redux-form props
        input,
        meta,
        flaggedDropdown,
      } = this.props

      const inputBlurHandler = input ? input.onBlur : onBlur
      const inputChangeHandler = input ? input.onChange : onChange
      const inputValue = input ? input.value : value

      const errorMessage = meta && meta.error ? meta.error : error
      const isTouched = meta ? meta.touched : touched
      const warnMessage = meta ? meta.warning : warning

      const isDisabled = disabled
      const isRequired = required || null
      const isMulti = multi || null

      const marginBottom = noMargin ? 0 : small ? 15 : 20
      const showErrorMessage = errorMessage
        ? isTouched || showErrorOnUnTouched
        : null
      const showWarnMessage = warnMessage
        ? isTouched || showWarnOnUnTouched
        : null

      const tooltipEl = tooltip ? (
        <Anchor tooltip={tooltip}>
          <Icon name="help" theme={styles.help} />
        </Anchor>
      ) : null

      return (
        <Block marginBottom={marginBottom}>
          <InputGroup alignTop={alignTop} small={small} style={style}>
            <InputLabel
              alignTop={alignTop}
              hideLabel={hideLabel}
              id={id}
              label={label}
              readOnly={readOnly}
              required={required}
              showErrorMessage={showErrorMessage}
              small={small}
              warningMessage={warningMessage}
            />
            <Block width="100%">
              <ComposedComponent
                allowAllDates={allowAllDates}
                autoComplete={autoComplete}
                autoFocus={autoFocus}
                capitalize={capitalize}
                checked={checked}
                className={className}
                clearable={clearable}
                customInputStyles={customInputStyles}
                dataTestId={dataTestId}
                dateFormat={dateFormat}
                defaultValue={defaultValue}
                disabled={isDisabled}
                disabledDays={disabledDays}
                disablePastDates={disablePastDates}
                disableSelectedList={disableSelectedList}
                disableTimepicker={disableTimepicker}
                error={showErrorMessage}
                getRejectedZones={getRejectedZones}
                ignoreTimezone={ignoreTimezone}
                indeterminate={indeterminate}
                infiniteScrolling={infiniteScrolling}
                isLoading={isLoading}
                isRowLoaded={isRowLoaded}
                isValidNewOption={isValidNewOption}
                listRowCount={listRowCount}
                loadMoreRows={loadMoreRows}
                location={location}
                maxDate={maxDate}
                minDate={minDate}
                multi={isMulti}
                multiline={multiline}
                name={name}
                numberOfMonths={numberOfMonths}
                onBlur={inputBlurHandler}
                onChange={inputChangeHandler}
                onKeyPress={this.handleKeyPress}
                onRemove={onRemove}
                onSearch={onSearch}
                placeholder={placeholder}
                options={options}
                overrideChecked={overrideChecked}
                readOnly={readOnly}
                refreshing={refreshing}
                required={isRequired}
                resource={resource}
                shouldSort={shouldSort}
                showRemoveItems={showRemoveItems}
                showSelectAll={showSelectAll}
                selectAllLimit={selectAllLimit}
                showPlusOneSign={showPlusOneSign}
                showRedBorder={showRedBorder}
                showTimePickerButton={showTimePickerButton}
                simpleValue={simpleValue}
                forceRerender={forceRerender}
                small={small}
                style={style}
                mapTargetMode={mapTargetMode}
                timeFormat={timeFormat}
                timezone={timezone}
                totalRowCount={totalRowCount}
                type={type}
                value={inputValue}
                values={values}
                warning={warning}
                warningMessage={warningMessage}
                flaggedDropdown={flaggedDropdown}
              >
                {children}
              </ComposedComponent>
              <Block>
                {description && (
                  <span style={styles.message}>{description}</span>
                )}
                {!hideError && showErrorMessage && (
                  <span style={[styles.message, styles.errorMessage]}>
                    {errorMessage}
                  </span>
                )}
                {!hideWarn && showWarnMessage && (
                  <span style={[styles.message, styles.errorMessage]}>
                    {warnMessage}
                  </span>
                )}
              </Block>
            </Block>
            {tooltipEl}
          </InputGroup>
        </Block>
      )
    }
  }

  return compose(pure, Radium)(FormInput)
}
