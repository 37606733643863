import { getLocationReference } from '@lighthouse/common'
import { getModule } from '@lighthouse/sdk'
import { find, get, reduce, round } from 'lodash'
import moment from 'moment-timezone'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import colors from 'config/theme/colors'

import Form from '../form'
import Panel from '../../../panel'
import { useUsers } from 'components/useUsers'
import { useTranslation } from 'react-i18next'

const LIST_ID = 'default'

const applicationUserModule = getModule('applicationUsers')
const areaModule = getModule('areas')
const auditTemplateModule = getModule('audits')
const auditEntryModule = getModule('auditEntries')

export default AuditEntry

function AuditEntry(props) {
  const {
    id: auditEntryId,
    onClose: handleClose,
    toggleExpand,
    toggleIcon,
  } = props

  const { t } = useTranslation()
  const { fetchUser } = useUsers()

  const dispatch = useDispatch()

  const [error, setError] = useState(null)
  const [isDeleting, setDeleting] = useState(false)
  const [isEditing, setEditing] = useState(false)

  // selectors
  const auditEntryCache = useSelector(state => state.auditEntries.cache)
  const auditTemplateCache = useSelector(state => state.audits.cache)
  const getUserFullName = useSelector(
    state => applicationUserModule.selectors(state)(LIST_ID).getUserFullName
  )
  const locations = useSelector(state =>
    areaModule
      .selectors(state)()
      .byType('location')
  )
  const timezone = useSelector(state => state.app.timezone)

  // audit entry
  const auditEntryResource = auditEntryCache[auditEntryId] || {}
  const auditEntryEntity = auditEntryResource.entity || {}

  // NOTE we check 'items' existence to see if we have the entire document and
  // therefore can determine if we need to refetch, other fields might also be
  // needed e.g headerFields and footerFields
  const hasAuditEntryItems = !!auditEntryEntity.items

  const auditTemplateId = auditEntryEntity.audit
  const auditUserId = auditEntryEntity.user
  const form = `audit-entry-${auditEntryId}`

  // audit template
  const auditTemplateResource = auditTemplateCache[auditTemplateId] || {}

  // has loaded/resolving state
  // audit entry
  const isAuditEntryResolved = auditEntryResource.state === 'resolved'
  const isAuditEntryResolving = auditEntryResource.state === 'resolving'
  const auditEntryError = auditEntryResource.error
  const hasLoadedAuditEntry =
    isAuditEntryResolved && hasAuditEntryItems && !auditEntryError

  // audit template
  const isAuditTemplateResolved = auditTemplateResource.state === 'resolved'
  const isAuditTemplateResolving = auditTemplateResource.state === 'resolving'
  const auditTemplateError = auditTemplateResource.error
  const hasLoadedAuditTemplate = isAuditTemplateResolved && !auditTemplateError

  const isLoaded =
    (hasLoadedAuditEntry && hasLoadedAuditTemplate) ||
    (hasLoadedAuditTemplate && auditEntryError)

  const isResolving = isAuditEntryResolving || isAuditTemplateResolving

  const title =
    isAuditEntryResolving && !isEditing
      ? 'Loading Audit'
      : isAuditTemplateResolving && !isEditing
      ? 'Loading Audit Template'
      : isAuditEntryResolving && isDeleting && isEditing
      ? 'Deleting Audit'
      : isAuditEntryResolving && !isDeleting && isEditing
      ? 'Saving Audit'
      : !isAuditEntryResolving && auditEntryEntity.title
      ? auditEntryEntity.title
      : 'Unknown Audit'

  function getInitialValues() {
    if (!isLoaded) return null

    const mutableEntity = auditEntryEntity.asMutable({ deep: true })

    const {
      area,
      audit,
      createdAt: timestamp,
      footerFields,
      gps,
      groupScores,
      headerFields,
      items,
      score,
      targetServiceLevel,
      target,
      title,
      user,
    } = mutableEntity

    const location = getLocationReference({ entity: auditEntryEntity })
    const userFullName = getUserFullName(user)

    const mTimezoneTime = moment.tz(timestamp, timezone)
    const timezoneTime = mTimezoneTime.format('MMM DD h:mma')
    const created = `${timezoneTime} by ${userFullName}`

    const actualScore = round(score.actual, 2)
    const maxScore = round(score.max, 2)

    const auditScore = `${actualScore}/${maxScore} (${score.percentageResult}%)`

    const normalizedItems = reduce(
      items,
      (accum, item) => {
        const { score } = item
        const matchingScore = find(item.scores, { value: score })

        if (matchingScore && matchingScore._id) {
          accum[item._id] = {
            assets: item.assets,
            comments: item.comments,
            followUpTriggered: item.followUpTriggered,
            score: matchingScore._id,
          }
        }
        return accum
      },
      {}
    )

    const groups = reduce(
      items,
      (accum, item) => {
        const groupId = item.group.id

        accum[groupId] = accum[groupId] || item.group

        return accum
      },
      {}
    )

    const serviceLevel =
      targetServiceLevel === 'above'
        ? 'Above Target'
        : targetServiceLevel === 'below'
        ? 'Below Target'
        : targetServiceLevel === 'on'
        ? 'On Target'
        : null

    const locationId = get(area, 'location.id')
    const geocodedLabel = get(gps, 'reverseGeocoded.label')

    const formLocation = find(locations, ['id', locationId])

    const label =
      (formLocation && formLocation.entity.name) ||
      geocodedLabel ||
      t('labelUnknownLocation')
    const value = (formLocation && formLocation.id) || 'custom-value'

    const nextInitialValues = {
      ...normalizedItems,
      groups,
      audit,
      auditScore,
      created,
      footerFields,
      gps,
      groupScores,
      headerFields,
      location,
      locationSelect: { label, value },
      targetServiceLevel: serviceLevel,
      target: `${target}%`,
      title,
    }

    return nextInitialValues
  }

  // resource errors
  useEffect(() => {
    const nextError = isResolving ? null : auditEntryError || auditTemplateError

    setError(nextError)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auditEntryEntity, auditTemplateError])

  // resource fetching
  useEffect(() => {
    if (isDeleting) return

    if (!hasLoadedAuditEntry && auditEntryId) {
      dispatch(auditEntryModule.findById(auditEntryId))
    }

    if (!hasLoadedAuditTemplate && auditTemplateId) {
      dispatch(auditTemplateModule.findById(auditTemplateId))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auditEntryId, auditTemplateId])

  // audit user fetching
  useEffect(() => {
    if (!auditUserId) return

    fetchUser({ userId: auditUserId })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auditUserId, dispatch])

  const initialValues = getInitialValues()

  return (
    <Panel
      color={colors.pink}
      error={error}
      isResolving={isResolving}
      onClose={handleClose}
      resource="Audit"
      title={title}
      toggleExpand={toggleExpand}
      toggleIcon={toggleIcon}
    >
      {initialValues && !isDeleting && (
        <Form
          form={form}
          handleCancel={() => setEditing(false)}
          handleEdit={() => setEditing(true)}
          id={auditEntryId}
          initialValues={initialValues}
          isEditing={isEditing}
          onClose={handleClose}
          readOnly={!isEditing}
          setError={setError}
          setDeleting={setDeleting}
          setEditing={setEditing}
          templateEntity={auditEntryEntity}
        />
      )}
    </Panel>
  )
}
