import { compose, withHandlers, withProps } from 'recompose'
import { includes } from 'lodash'
import { withRouter } from 'react-router'
import queryString from 'query-string'
import React from 'react'

import { Block, Flex } from 'components/common'
import Icon from 'components/icon'
import emitter from 'utils/emitter'

export default compose(
  withProps(props => ({
    isClickableType: includes(
      ['auditentries', 'issue', 'issues', 'signal', 'taskentries'],
      props.type
    ),
  })),
  withRouter,
  withHandlers({ onClick })
)(MapPopupLabel)

function MapPopupLabel(props) {
  const { isClickableType, iconColor, iconType, label, onClick } = props

  const cursor = isClickableType ? 'pointer' : null

  return (
    <Flex
      alignItems="center"
      cursor={cursor}
      onClick={onClick}
      fontWeight={500}
      fontSize="12px"
    >
      <Icon
        name={iconType}
        fontSize="16px"
        paddingRight={7}
        color={iconColor}
      />
      <Block
        maxWidth={200}
        overflow="hidden"
        textOverflow="ellipsis"
        title={label}
        whiteSpace="nowrap"
      >
        {label}
      </Block>
    </Flex>
  )
}

function onClick(props) {
  const { isClickableType, geometry, history, id, label, type } = props

  return () => {
    if (!isClickableType || !geometry) return

    emitter.emit('markers:closePopup')

    const {
      coordinates: [lng, lat],
    } = geometry

    // NOTE return if invalid coordinates
    if (!lat || !lng) return

    const nextSearch = queryString.stringify({
      id,
      lat,
      lng,
      resource: type,
      showMarker: true,
      title: label,
    })

    history.push({ search: `?${nextSearch}` })
  }
}
