import { memoize } from 'lodash'

export const getStatusDetails = memoize(status => {
  switch (status) {
    case 'cancelled':
      return {
        style: {
          color: '#AAA',
        },
        text: 'Cancelled',
      }
    case 'closed':
      return {
        style: {
          color: '#D0021B',
        },
        text: 'Closed',
      }
    case 'in-progress':
      return {
        style: {
          color: '#00AAE4',
        },
        text: 'In Progress',
      }
    case 'open':
      return {
        style: {
          color: '#FF8C2F',
        },
        text: 'Open',
      }
    default:
      return {
        style: {
          color: '#666666',
        },
        text: 'Unknown',
      }
  }
})
