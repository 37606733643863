import {
  capitalize,
  compact,
  filter,
  get,
  includes,
  isArray,
  isBoolean,
  isEmpty,
  isEqual,
  isNil,
  isString,
  isUndefined,
  map,
  memoize,
  noop,
  orderBy,
  trim,
  toLower,
  uniqWith,
} from 'lodash'
import {
  compose,
  lifecycle,
  withHandlers,
  withProps,
  withPropsOnChange,
  withState,
} from 'recompose'
import debounce from 'debounce-promise'
import Downshift from 'downshift'
import Measure from 'react-measure'
import onClickOutside from 'react-onclickoutside'
import Promise from 'bluebird'
import PropTypes from 'prop-types'
import React from 'react'

import { colors, typography } from 'config/theme'
import { Flex } from 'components/common'
import Dropdown from './components/dropdown'
import DropdownDeprecated from './components/dropdown-deprecated'
import DropdownMenu from './components/dropdown-menu'

import * as logger from 'utils/logger'

const color = colors.gray.darker
const fontFamily = typography.defaultFontFamily
const rootStyles = { color, fontFamily }

export default compose(
  withState('isLoading', 'setLoading', true),
  withState('isOpen', 'setOpen', false),
  withState('isSearching', 'setSearching', false),
  withState('items', 'setItems', []),
  withState('selectedItems', 'setSelectedItems', []),
  withProps(({ isOpen }) => ({
    disableOnClickOutside: !isOpen,
  })),
  withPropsOnChange([], props => ({
    handleSearch: props.onSearch ? debounce(props.onSearch, 700) : null,
  })),
  withHandlers({
    handleBlur,
    handleChange,
  }),
  withHandlers({
    addItem,
    addItems,
    removeItems,
    removeItem,
    handleContainerClick,
    handleClickOutside,
  }),
  withHandlers({
    handleClear,
    handleSelection,
    handleInputValueChange,
    loadOptions,
  }),
  lifecycle({
    componentDidMount,
    componentWillReceiveProps,
  }),
  onClickOutside
)(Select)

function Select(props) {
  const {
    addItems,
    clearable,
    dataTestId,
    disabled,
    disableSelectedList,
    error,
    handleBlur,
    handleContainerClick,
    handleClear,
    handleInputValueChange,
    handleSelection,
    hasError,
    infiniteScrolling,
    isLoading,
    isOpen,
    isRowLoaded,
    isSearching,
    items,
    listRowCount,
    loadMoreRows,
    multi,
    placeholder,
    readOnly,
    refreshing,
    removeItem,
    removeItems,
    showRemoveItems,
    showSelectAll,
    selectAllLimit,
    selectedItems,
    shouldSort,
    totalRowCount,
    value,
    widthText,
    type,
    warningMessage,
    flaggedDropdown,
  } = props

  const DropdownComponent = flaggedDropdown ? Dropdown : DropdownDeprecated

  const itemCount = items.length

  // NOTE this has to be in scope of function. Will probably be better to move
  // to handler, but we'd need to manage the inputValue state of downshift
  const memoizedGetItems = memoize(getItems)

  const showDropdown = !disabled && isOpen
  return (
    <Downshift
      itemCount={itemCount}
      itemToString={item => (item ? item.label : '')}
      onChange={handleSelection}
      onInputValueChange={handleInputValueChange}
      selectedItem={null}
      stateReducer={stateReducer}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        highlightedIndex,
        inputValue,
        selectItemAtIndex,
        setHighlightedIndex,
      }) => {
        const filteredItems = memoizedGetItems(inputValue, {
          items,
          inputValue,
          multi,
          shouldSort,
        })
        return (
          /** NOTE root to Downshift has to be div **/
          <div data-testid={dataTestId} style={rootStyles}>
            <Measure>
              {({ width = 0 }) => (
                <Flex flexDirection="column">
                  <div
                    data-testid="select-container"
                    onClick={handleContainerClick}
                  >
                    <DropdownComponent
                      clearable={clearable}
                      disabled={disabled}
                      disableSelectedList={disableSelectedList}
                      getInputProps={getInputProps}
                      handleBlur={handleBlur}
                      handleClear={handleClear}
                      hasError={hasError || error}
                      highlightedIndex={highlightedIndex}
                      inputValue={inputValue}
                      isLoading={isLoading}
                      isOpen={isOpen}
                      isSearching={isSearching}
                      items={filteredItems}
                      multi={multi}
                      placeholder={placeholder}
                      readOnly={readOnly}
                      removeItem={removeItem}
                      selectedItems={selectedItems}
                      selectItemAtIndex={selectItemAtIndex}
                      setHighlightedIndex={setHighlightedIndex}
                      width={width}
                      widthText={widthText}
                      value={value}
                      warningMessage={warningMessage}
                    />
                  </div>
                  {showDropdown && (
                    <DropdownMenu
                      addItems={addItems}
                      dataTestId="dropdownmenu"
                      disabled={disabled}
                      getItemProps={getItemProps}
                      getMenuProps={getMenuProps}
                      highlightedIndex={highlightedIndex}
                      infiniteScrolling={infiniteScrolling}
                      inputValue={inputValue}
                      isRowLoaded={isRowLoaded}
                      isSearching={isSearching}
                      items={filteredItems}
                      listRowCount={listRowCount}
                      loadMoreRows={loadMoreRows}
                      multi={multi}
                      refreshing={refreshing}
                      removeItems={removeItems}
                      showRemoveItems={showRemoveItems}
                      showSelectAll={showSelectAll}
                      selectAllLimit={selectAllLimit}
                      selectedItems={selectedItems}
                      totalRowCount={totalRowCount}
                      type={type}
                      value={value}
                      width={width}
                      warningMessage={warningMessage}
                    />
                  )}
                </Flex>
              )}
            </Measure>
          </div>
        )
      }}
    </Downshift>
  )
}

Select.propTypes = {
  clearable: PropTypes.bool,
  disabled: PropTypes.bool,
  disableSelectedList: PropTypes.bool,
  handleClear: PropTypes.func.isRequired,
  handleContainerClick: PropTypes.func.isRequired,
  handleSelection: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  items: PropTypes.array,
  multi: PropTypes.bool,
  onInputValueChange: PropTypes.func,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  removeItem: PropTypes.func.isRequired,
  showSelectAll: PropTypes.bool,
  selectAllLimit: PropTypes.number,
  selectedItem: PropTypes.object,
  selectedItems: PropTypes.array.isRequired,
  shouldSort: PropTypes.bool,
  type: PropTypes.string,
  warningMessage: PropTypes.string,
}

function componentDidMount() {
  const { loadOptions, options } = this.props
  loadOptions(options)
}

function componentWillReceiveProps(nextProps = {}) {
  const currentProps = this.props || {}
  const {
    items = [],
    loadOptions,
    options = [],
    reverseLocation,
    value,
    forceRerender,
  } = nextProps

  const currentValue = get(currentProps, 'input.value') || currentProps.value
  const type = currentProps.type

  const nextValue = get(nextProps, 'input.value') || nextProps.value

  const optionsChanged = currentProps.options.length !== options.length
  const itemsChanged = currentProps.items.length !== items.length
  const valueChanged = currentValue !== nextValue
  // NOTE Be very aware of side effects of using this. Notice in cDM we resolve
  // our options because they can be async? If however you pass static options,
  // and then immediately update those, there's a chance the cDM promise
  // fulfillment handler runs _after_ the cWRP handler even though it was called
  // before it. Long term we need to rethink this pattern of updating options
  const doRerender = forceRerender !== currentProps.forceRerender
  if ((optionsChanged && options.length > 0) || doRerender) {
    loadOptions(options)
  }

  if (itemsChanged || valueChanged || doRerender) {
    const selectedItems = reverseLocation
      ? [value]
      : getSelectedItems(items, nextValue, type)
    currentProps.setSelectedItems(selectedItems)
  }
}

function addItem({ handleChange, items, multi, selectedItems }) {
  return ({ item = {} }) => {
    const nextSelected = multi
      ? item.value === '*'
        ? items
        : [...selectedItems, item]
      : [item]

    const nextValues = multi ? map(nextSelected, 'value') : item.value

    handleChange(nextValues)
  }
}

function addItems({ handleChange, selectedItems }) {
  return (itemsToAdd = []) => {
    const nextSelected = [...selectedItems, ...itemsToAdd]
    const nextValues = map(nextSelected, 'value')

    handleChange(nextValues)
  }
}

function getItems(inputValue = '', { items, shouldSort }) {
  const resultItems = parseItems({ inputValue, items, shouldSort })
  return resultItems
}

function loadOptions(props) {
  const {
    defaultValue,
    input = {},
    reverseLocation,
    setItems,
    setLoading,
    setSelectedItems,
    value,
    type,
  } = props

  return options => {
    setLoading(true)
    return Promise.resolve(options)
      .then(items => {
        const inputValue = !isNilValue(value)
          ? value
          : !isNilValue(input.value)
          ? input.value
          : defaultValue

        const selectedItems =
          reverseLocation && value.value
            ? [value]
            : getSelectedItems(items, inputValue, type)

        setItems(items)
        setSelectedItems(selectedItems)
      })
      .catch(err => logger.error(`Select error :: ${err.message}`, err))
      .finally(() => setLoading(false))
  }
}

function parseItems({ inputValue, items, shouldSort }) {
  const filteredItems = filter(items, item => {
    if (isNil(item.value)) return false
    if (!inputValue) return true

    const search = trim(toLower(inputValue))
    const label = trim(toLower(item.search) || toLower(item.label))
    return includes(label, search)
  })

  if (!shouldSort) return filteredItems

  return orderBy(filteredItems, ['label', 'asc'])
}

function getSelectedItems(items = [], value = '', type) {
  const selectedValues = isArray(value) ? value : [value]
  if (!value && typeof value !== 'boolean' && typeof value !== 'number') {
    return []
  }

  const matches = {}

  for (const selectedValue of selectedValues) {
    matches[selectedValue] = false
  }

  const selectedItems = filter(items, ({ value }) => {
    const match = selectedValues.includes(value)

    if (match) {
      matches[value] = true
    }

    return match
  })

  for (const selectedValue of selectedValues) {
    if (!matches[selectedValue]) {
      console.warn(`Unknown option for type: ${type}, value: ${selectedValue}`)
      if (type === 'locationGroup') {
        selectedItems.unshift({
          label: 'Unknown Location Group',
          value: selectedValue,
        })
      } else if (type) {
        selectedItems.unshift({
          label: 'Unknown ' + capitalize(type),
          value: selectedValue,
        })
      } else {
        // NOTE This accounts for unknown values should be shown in their raw
        // format. E.g. a country which isn't one of our defaults
        // (CountrySelect), or a time option (12:15) which is not a default.
        // It's better to show these than hide them or display as unknown
        selectedItems.unshift({ label: selectedValue, value: selectedValue })
      }
    }
  }

  return selectedItems
}

function handleBlur({ onBlur, input = {} }) {
  return value => {
    const blurHandler = input.onBlur || onBlur

    if (!onBlur) {
      console.error('Missing `onBlur` prop for `Select` component')
      return
    }

    blurHandler(value)
  }
}

function handleChange({ onChange, input = {} }) {
  return value => {
    const changeHandler = input.onChange || onChange

    if (!changeHandler) {
      console.error('Missing `onChange` prop for `Select` component')
      return
    }

    changeHandler(value)
  }
}

function handleContainerClick({ disabled, isOpen, readOnly, setOpen, value }) {
  return disabled || isOpen || readOnly
    ? noop
    : () => {
        setOpen(true)
      }
}

function handleClickOutside({ setOpen, value }) {
  return () => {
    setOpen(false)
  }
}

function handleInputValueChange(props) {
  const { items: currentItems, handleSearch, setItems, setSearching } = props

  return (value, stateHelpers) => {
    const { selectedItem } = stateHelpers

    if (!handleSearch) return

    // NOTE: Downshift sets the selected item internally and fires
    // the onInputValueChange handler before the state is run through
    // our custom reducer. If the selectedItem (the item we just selected) is
    // the same as the value we have recieved don't fetch!
    // See: https://github.com/downshift-js/downshift/blob/v3.1.5/src/downshift.js#L366
    if (selectedItem && selectedItem.label === value) return

    setSearching(true)

    return Promise.resolve(handleSearch(value))
      .then((items = []) => {
        const nextItems = uniqWith([...currentItems, ...items], isEqual)
        setItems(nextItems)
      })
      .catch(err => logger.error(`Select error :: ${err.message}`, err))
      .finally(() => setSearching(false))
  }
}

function handleClear(props) {
  const { handleChange } = props
  return () => {
    return handleChange([])
  }
}

function handleSelection(props) {
  const {
    addItem,
    items,
    multi,
    removeItem,
    selectedItems,
    setOpen,
    value: selectedValue = [],
  } = props

  return (item, downshift) => {
    if (!multi) setOpen(false)

    const { value: itemValue } = item
    const isSelectAll = itemValue === '*'

    if (isSelectAll) {
      const selectedItemCount = selectedItems.length
      const totalItemCount = items.length

      const action = selectedItemCount === totalItemCount ? removeItem : addItem

      return action({ ...downshift, item })
    }

    const itemSelected = multi
      ? selectedValue.includes(itemValue)
      : selectedValue === itemValue

    // NOTE skip action when selecting same item in non-multi select
    if (itemSelected && !multi) return

    const action = itemSelected ? removeItem : addItem

    return action({ ...downshift, item })
  }
}

function removeItem({ multi, handleChange, selectedItems }) {
  return ({ item }) => {
    const nextSelected = multi
      ? item.value === '*'
        ? []
        : selectedItems.filter(i => !isEqual(item, i))
      : [item]

    const nextValues = multi ? map(nextSelected, 'value') : null

    handleChange(nextValues)
  }
}

function removeItems({ handleChange, selectedItems }) {
  return (itemsToRemove = []) => {
    const nextSelected = selectedItems.filter(
      item => !itemsToRemove.includes(item)
    )
    const nextValues = map(nextSelected, 'value')

    handleChange(nextValues)
  }
}

// Check for valid value based on various accepted types. Valid types are
// null, booleans, non-empty arrays and strings with 1 or more characters
export function isNilValue(value) {
  return isUndefined(value)
    ? true
    : isBoolean(value)
    ? false
    : isArray(value)
    ? isEmpty(value)
    : isString(value)
    ? value === ''
    : false
}

// https://codesandbox.io/s/github/kentcdodds/downshift-examples/tree/master/?module=%2Fsrc%2Fordered-examples%2F04-multi-select.js&moduleview=1
function stateReducer(state, changes) {
  // this prevents the menu from being closed when the user
  // selects an item with a keyboard or mouse
  switch (changes.type) {
    case Downshift.stateChangeTypes.clickItem:
    case Downshift.stateChangeTypes.keyDownEnter:
      return {
        ...changes,
        isOpen: true,
        highlightedIndex: state.highlightedIndex,
        // NOTE: we pass the previous state input value here otherwise
        // downshift will set the selected item label as the input value. If a
        // user wants to select multiple items within a search this means they
        // don't have to enter the same search term each time!
        inputValue: state.inputValue,
      }
    default:
      return changes
  }
}
