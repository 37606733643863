import PropTypes from 'prop-types'
import Radium from 'radium'
import React, { Component } from 'react'
import styles from './styles'

export default Radium(FieldGroups)

function FieldGroups(props) {
  const { border, boxed, children, description, horizontal, title } = props

  return (
    <div>
      {title && <h2 style={[styles.title]}>{title}</h2>}
      <div
        style={[
          styles.root,
          border && styles.bordered,
          boxed && styles.boxed,
          horizontal && styles.horizontal,
        ]}
      >
        {description && <p style={styles.description}>{description}</p>}
        {children}
      </div>
    </div>
  )
}

FieldGroups.defaultProps = {
  border: true,
}

FieldGroups.propTypes = {
  border: PropTypes.bool,
  boxed: PropTypes.bool,
  description: PropTypes.string,
  title: PropTypes.string,
  horizontal: PropTypes.bool,
}
