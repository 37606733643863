import {
  chain,
  compact,
  filter,
  first,
  get,
  includes,
  isEmpty,
  isNil,
  map,
  memoize,
} from 'lodash'

import { compose, withHandlers, withState } from 'recompose'
import { connect } from 'react-redux'
import { getModule } from '@lighthouse/sdk'
import { withRouter } from 'react-router-dom'
import Immutable from 'seamless-immutable'
import queryString from 'query-string'
import React from 'react'

import { Block, Flex } from 'components/common'
import ListAction from 'components/list-next/components/action'
import ListContent from 'components/list-next/components/content'
import ListDescription from 'components/list-next/components/description'
import ListHeader from 'components/list-next/components/header'
import ListIcon from 'components/list-next/components/icon'
import ListTitle from 'components/list-next/components/title'
import ListWrapper from 'components/list-next'
import NoResults from 'components/no-results'
import Text from 'components/form/text'
import { useTranslation } from 'react-i18next'
import { getDateInTimezone } from 'helpers/datetime'

const areaModule = getModule('areas')
const geoModule = getModule('geo')

const memoizedGetFloors = memoize(getFloors, (building, marker) => marker.id)

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withState('searchText', 'setSearchText', ''),
  withHandlers({
    setMarkerFilter,
  }),
  withHandlers({
    filterMarkers,
    onClick,
  })
)(PanelRightSearch)

function PanelRightSearch(props) {
  const {
    filterMarkers,
    findEnclosing,
    onClick,
    handleClose,
    searchText,
    setSearchText,
    timezone,
    urlId,
  } = props

  const { t } = useTranslation()

  const filteredMarkers = filterMarkers()

  const listItems = map(filteredMarkers, marker => {
    const { geometry, id, properties = {} } = marker

    const { label, type } = properties

    const isFocused = urlId === marker.id

    const focusedIcon = isFocused ? 'close' : 'map-target'

    const typeIcon =
      type === 'signal' ? 'beacon' : type === 'user' ? 'user' : 'alert'

    const enclosingBuilding = findEnclosing(geometry, 'building')
    const buildingName = enclosingBuilding && enclosingBuilding.entity.name
    const markerFloors = memoizedGetFloors(enclosingBuilding, marker)
    const resultClickHandler = () =>
      onClick(enclosingBuilding, isFocused, marker)

    return (
      <ListWrapper key={id}>
        <Block>
          <ListIcon name={typeIcon} styles={{ fontSize: '24px' }} />
        </Block>
        <ListContent>
          <ListTitle marginBottom={5}>{label}</ListTitle>
          <ListDescription>
            <MarkerDescription
              buildingName={buildingName}
              floors={markerFloors}
              marker={marker}
              t={t}
              timezone={timezone}
            />
          </ListDescription>
        </ListContent>
        <Block>
          <ListAction
            dataTestId="right-panel-search-button"
            icon={focusedIcon}
            marginRight={0}
            onClick={resultClickHandler}
          />
        </Block>
      </ListWrapper>
    )
  })

  const hasResultsAndSearch = !!listItems.length && !!searchText
  const hasNoResultsAndSearch = !listItems.length && searchText
  const hasNoResultsAndNoSearch = !listItems.length && !searchText

  return (
    <Flex flexDirection="column">
      <ListHeader handleClose={handleClose}>
        <Text
          autoFocus
          dataTestId="signal-search-icon"
          onChange={e => setSearchText(e.target.value)}
          required
          style={[
            {
              borderStyle: 'none',
              width: 300,
              height: 60,
              maxHeight: 'auto',
              marginBottom: 0,
            },
          ]}
          placeholder={t('placeholder.search_ellipsis')}
          value={searchText}
        />
      </ListHeader>
      <Block width="100%">
        {hasResultsAndSearch && compact(listItems)}
        {hasNoResultsAndSearch && <NoResults message={t('textNoResults')} />}
        {hasNoResultsAndNoSearch && (
          <NoResults message={t('searchPanel.noSearch')} />
        )}
      </Block>
    </Flex>
  )
}

function filterMarkers(props) {
  const { searchText } = props
  const regex = new RegExp(searchText, 'i')
  // TODO move to SDK
  return () => {
    if (!searchText) return []

    return filter(props.markers, marker => {
      const searchString = get(marker, 'properties.search', '')
      if (!searchString) return false
      const result = regex.test(searchString)
      return result
    })
  }
}

function getFloors(building, marker) {
  const buildingFloors = get(building, 'entity.floors')
  const floorsRef = get(marker, 'properties.floorsRef')

  if (!buildingFloors || !floorsRef) return []

  // NOTE a marker can exist on multiple floors
  const matchingFloors = filter(buildingFloors, floor =>
    includes(floorsRef, floor.level)
  )

  const floorsString =
    !isEmpty(matchingFloors) &&
    chain(matchingFloors)
      .map('label')
      .join(', ')
      .value()

  return floorsString
}

function onClick(props) {
  const { history } = props

  return (building, isFocused, marker = {}) => {
    if (isFocused) {
      return history.push({ search: '' })
    }

    const { id, geometry, properties = {} } = marker
    const { type, label } = properties
    const floor = first(properties.floorsRef)
    const isIndoors = building && !isNil(floor)
    const isUser = type === 'user'
    const setBuildingOpts = isIndoors
      ? {
          id: building.entity._id,
          floor,
        }
      : {}
    // NOTE If we're focusing a user marker which is outdoors it helps to set a
    // sane zoom level. This might need more thought once we introduce setting
    // the map zoom via bounds
    const shouldSetZoom = isUser && !isIndoors
    const zoom = shouldSetZoom ? 17 : props.currentZoom

    const {
      coordinates: [lng, lat],
    } = geometry

    const nextSearch = queryString.stringify({
      id,
      lat,
      lng,
      resource: type,
      showMarker: true,
      title: label,
    })
    history.push({ search: `?${nextSearch}` })

    props.setBuilding(setBuildingOpts)
    props.setMarkerFilter(type)
    props.setMapProperties({
      center: marker.geometry,
      zoom,
    })
  }
}

function MarkerDescription(props) {
  const { buildingName = '', floors, marker, t, timezone } = props

  const { type, timestamp } = marker.properties || {}

  const isIssue = type && type.includes('issue')
  const isSignal = type === 'signal'
  const isUser = type === 'user'

  if (isIssue || isSignal) {
    return (
      <Block>
        <Block>{buildingName}</Block>
        {!isEmpty(floors) && <Block>{floors}</Block>}
      </Block>
    )
  }

  if (isUser && timestamp) {
    const datetime = getDateInTimezone(timestamp, timezone).format(
      'MMM DD YYYY HH:mm'
    )

    return <div>{t('searchPanel.userLastSeen', { datetime })}</div>
  }

  return null
}

function mapStateToProps(state) {
  const areaSelectors = areaModule.selectors(state)()

  return {
    currentZoom: get(state, 'geo.properties.zoom'),
    findEnclosing: areaSelectors.findEnclosing,
    markerFilters: get(state, 'geo.markers.filters', {}),
    markers: get(state, 'geo.markers.collection'),
    timezone: state.app.timezone,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setBuilding: opts => dispatch(geoModule.setBuilding(opts)),
    setMarkerFilters: filters => dispatch(geoModule.setMarkerFilters(filters)),
    setMapProperties: properties =>
      dispatch(geoModule.setProperties(properties)),
  }
}

function setMarkerFilter(props) {
  const { markerFilters, setMarkerFilters } = props
  const { types = {} } = markerFilters

  return markerType => {
    if (types[markerType]) return

    const nextFilters = Immutable.setIn(
      markerFilters,
      ['types', markerType],
      true
    )

    setMarkerFilters(nextFilters)
  }
}
