import { attempt, includes, isError, omit, toNumber } from 'lodash'
import {
  compose,
  lifecycle,
  withHandlers,
  withPropsOnChange,
  withState,
} from 'recompose'
import { point } from '@turf/helpers'
import { withLeaflet } from 'react-leaflet'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import React from 'react'

import FlagsHOC from 'components/flags/hoc'
import TemporaryMarkerLayer from 'components/mapping/temporary-marker-layer'
import AuditTemporaryMarkerLayer from 'components/mapping/temporary-marker-layer/audit'
import withUrlQuery from 'components/with-url-query'
import emitter from 'utils/emitter'
import { withTranslation } from 'react-i18next'

interface UrlQuery {
  action: string
  id: string
  lat: number
  lng: number
  resource: string
  title: string
}

interface TemporaryMarkerProps {
  action: string
  coordinates: number[]
  draggable: boolean
  handleClick: () => void
  handleDragEnd: () => void
  handleMouseMove: () => void
  icon: string
  id: string
  isSupportedResource: boolean
  mouseCoordinates: []
  resource: string
  score: string
  showMarker: string
  tooltipText: string
  urlQuery: UrlQuery
  t?: (translationKey: string) => void
  targetServiceLevel?: string
}

interface LatLng {
  latlng: {
    lat: number
    lng: number
  }
}

interface Target {
  target: {
    _latlng: {
      lat: number
      lng: number
    }
  }
}

const SUPPORTED_RESOURCE_TYPES = [
  'address',
  'auditentries',
  'issue',
  'issues',
  'signal',
  'taskentries',
]

export default compose(
  FlagsHOC,
  withTranslation(),
  withUrlQuery,
  withRouter,
  withLeaflet,
  withPropsOnChange(['urlQuery'], buildUrlQueryProps),
  withState('mouseCoordinates', 'setMouseCoordinates'),
  withHandlers({ handleClick, handleDragEnd, handleMouseMove }),
  lifecycle({ componentDidMount, componentDidUpdate, componentWillUnmount })
)(TemporaryMarker)

function TemporaryMarker(props: TemporaryMarkerProps): JSX.Element {
  const {
    coordinates,
    draggable,
    handleClick,
    handleDragEnd,
    icon,
    isSupportedResource,
    mouseCoordinates,
    resource,
    score,
    showMarker,
    targetServiceLevel,
    tooltipText,
  } = props

  // NOTE: only render when valid new/existing marker and a supported resource
  // there are scenarios where a marker might not have coordinates
  if (!isSupportedResource || showMarker === 'false') return null

  const coords = coordinates
    ? { lat: coordinates[1], lng: coordinates[0] }
    : mouseCoordinates

  if (resource === 'auditentries') {
    return (
      <AuditTemporaryMarkerLayer
        coordinates={coords}
        draggable={draggable}
        icon={icon}
        onSet={handleClick}
        onDragEnd={handleDragEnd}
        resource={resource}
        score={score}
        targetServiceLevel={targetServiceLevel}
        title={tooltipText}
      />
    )
  }

  return (
    <TemporaryMarkerLayer
      coordinates={coords}
      draggable={draggable}
      icon={icon}
      onSet={handleClick}
      onDragEnd={handleDragEnd}
      resource={resource}
      title={tooltipText}
    />
  )
}

function buildUrlQueryProps(props: TemporaryMarkerProps) {
  const { urlQuery, t } = props
  const { action, id, lat, lng, resource, title } = urlQuery

  const lngLat = [toNumber(lng), toNumber(lat)]
  const geoJsonPoint = attempt(point, lngLat)
  const validPoint = !isError(geoJsonPoint)

  const coordinates = validPoint ? lngLat : null
  const draggable = action === 'edit'
  const isNew = !!(!id && resource)
  const isSupportedResource = includes(SUPPORTED_RESOURCE_TYPES, resource)
  const resourceType = resource?.includes('issue') ? 'issue' : resource

  const tooltipText = !coordinates
    ? t('map.tooltip.clickToPlace')
    : isNew
    ? t(`map.tooltip.new.${resourceType}`)
    : draggable
    ? `${title} (Drag to reposition)`
    : title

  return {
    ...urlQuery,
    coordinates,
    draggable,
    isNew,
    isSupportedResource,
    tooltipText,
  }
}

function componentDidMount(): void {
  const { coordinates, handleMouseMove, leaflet } = this.props

  if (coordinates) {
    const center = { coordinates, type: 'Point' }
    emitter.emit('map:set-properties', { center })
  }

  leaflet.map.on('mousemove', handleMouseMove)
}

function componentDidUpdate(prevProps): void {
  const { coordinates } = this.props
  const prevCoordinates = prevProps.coordinates

  if (coordinates && prevCoordinates !== coordinates) {
    const center = { coordinates, type: 'Point' }
    emitter.emit('map:set-properties', { center })
  }
}

function componentWillUnmount(): void {
  const { leaflet, handleMouseMove } = this.props
  leaflet.map.off('mousemove', handleMouseMove)
}

function handleDragEnd(props): (target: Target) => void {
  const { history, urlQuery } = props

  return ({ target }): void => {
    const {
      _latlng: { lat, lng },
    } = target
    const nextSearch = queryString.stringify({
      ...urlQuery,
      lat,
      lng,
      showMarker: true,
    })
    history.push({ search: `?${nextSearch}` })
  }
}

function handleMouseMove(props): ({ latlng }: LatLng) => void {
  const { coordinates, setMouseCoordinates } = props

  return ({ latlng }): void => {
    // NOTE if we have coordinates no need to set mouseCoordinates
    if (coordinates) return
    setMouseCoordinates([latlng.lat, latlng.lng])
  }
}

function handleClick(props): (latlng: LatLng) => void {
  const { lat, lng, isNew, history, resource, urlQuery } = props

  return async ({ latlng }): Promise<void> => {
    // NOTE: we only respond to a click event under the following circumstances
    // 1. resource is not a geocoded address which is shown when clicking a non
    //    location search bar result, these icons are static and cannot be moved
    // 2. a new entity is being added, which enable the user to click to reposition
    // 3. an existing entity is being updated, we only enable the user to
    //    reposition when selecting the location search target icon
    const canSet = resource !== 'address' && (isNew || (!lat && !lng))

    if (canSet) {
      // NOTE: when we have an existing lat and lng omit and set the url query,
      // this enables a user to reposition a new entity by clicking on the icon.
      if (urlQuery.lat && urlQuery.lng) {
        const repositionQuery = omit({ ...urlQuery }, ['lat', 'lng'])
        const repositionSearch = queryString.stringify(repositionQuery)

        return history.push({ search: `?${repositionSearch}` })
      }

      const markerQuery = { ...urlQuery, ...latlng }
      const markerSearch = queryString.stringify(markerQuery)

      history.push({ search: `?${markerSearch}` })
    }
  }
}
