import {
  capitalize,
  find,
  flattenDeep,
  get,
  includes,
  map,
  reduce,
  truncate,
} from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Babushka from 'components/babushka'
import Button from 'components/button'
import { Block, Flex } from 'components/common'
import FilterSearch from 'components/filter/search'
import MenuButton from 'components/filter-next/filter-menu-button'
import ResultCount from 'components/filter-next/result-count'
import Icon from 'components/icon'
import useToggle from 'components/useToggle'
import colors from 'config/theme/colors'

import Tags from './tags'

interface Props {
  allCaches: object
  categories: {
    children: {
      [key: string]: {
        children: {
          [key: string]: {
            label: string
            options: {
              [key: string]: {
                entity: {
                  _id: string
                  name: string
                }
              }
            }
          }
        }
        label: string
        selectable: boolean
        typeLabel?: string
      }
    }
  }
  enableCount?: boolean
  enableFilter?: boolean
  enableSearch?: boolean
  loading: boolean
  onClearFilter: Function
  onClearAllFilters: Function
  onSearch?: Function
  onSetFilter: Function
  resultsCount?: number
  search?: string
  selectedFilters: object
  pageKey?: string
  totalCount?: number
}

export default function FilterMenu(props: Props): JSX.Element {
  const { t } = useTranslation()

  const [active, setActive] = useState([])
  const [expandIconVisible, setExpandIconVisibility] = useState(false)
  const [visible, setVisibility] = useState(false)
  const {
    allCaches,
    categories,
    enableCount = true,
    enableFilter = true,
    enableSearch = false,
    loading,
    onClearFilter,
    onClearAllFilters,
    onSearch: handleSetSearch,
    onSetFilter,
    pageKey,
    resultsCount,
    search,
    selectedFilters,
    totalCount = 0,
  } = props

  const ONE_LINE_HEIGHT = 60
  const filterBarRef = useRef<HTMLDivElement>()
  const areaCache = useSelector((state): object => state.areas.cache)
  const [tagsVisible, setTagsVisibility] = useToggle({
    key: `${pageKey}:tagsVisible`,
  })

  const icon = tagsVisible ? 'neutral' : 'arrow-down'

  // TODO This is tech debt and a side effect of re-using logic to support
  // multiple concepts. We should look at rewriting this in future with a view
  // to keep the logic contextual. E.g. below we refer to filters specific to a
  // page (e.g. exception filters) that should not have to be specified here in
  // this generic component
  function buildTags(caches, selected): object[] {
    const reduced = reduce(
      selected,
      (accum, values: string | [], key): object => {
        if (typeof values !== 'string') {
          const tags = map(values, (documentId: string): object => {
            const typeLabel =
              categories?.children[`${key}s`]?.children?.typeLabel ||
              get(caches, `${key}s.label`)

            const { _id: value, name: label } = get(
              caches,
              `${key}s.options.${documentId}.entity`,
              {}
            )

            if (key === 'user') {
              const user = find(caches.users.options, [
                'entity.user._id',
                documentId,
              ])
              const { _id: value, fullName: label } = user.entity.user

              return {
                typeLabel,
                label,
                type: key,
                value,
              }
            }

            if (key === 'targetServiceLevel') {
              const label = t(`label${capitalize(documentId)}Target`)
              return {
                typeLabel: t('labelScore'),
                label,
                type: key,
                value: documentId,
              }
            }

            if (key === 'area') {
              const area = get(
                caches,
                `${key}s.options.${documentId}.entity`,
                {}
              )

              const { _id: value, type } = area
              const isLocationArea = type === 'location'
              const isLocationGroupArea = type === 'location-group'
              if (isLocationGroupArea) {
                const name = area?.name || t('labelUnknownLocationGroup')

                return {
                  typeLabel: t('labelLocationGroup'),
                  label: name,
                  type: 'area',
                  value,
                }
              }
              const refVal = area?.reference || ''
              const sin = area?.plugins?.timegate?.options?.SiteSIN
              const jobNumber = area?.plugins?.winteam?.options?.jobNumber
              const ref = sin
                ? `SIN: ${sin}`
                : jobNumber
                ? `Job #${jobNumber}`
                : `Ref: #${refVal}`
              const hasRef = sin || jobNumber || refVal

              const unknownAreaLabel = isLocationGroupArea
                ? t('labelUnknownLocationGroup')
                : t('labelUnknownArea')
              const areaName = area?.name || unknownAreaLabel

              const areaLabel = hasRef ? `${areaName}, ${ref}` : areaName

              if (!isLocationArea) {
                const { childOf } = area

                const parentLocation = get(areaCache, `${childOf}.entity`, {})
                const parentLocationName =
                  parentLocation?.name || 'Unknown Location'

                const typeLabel = type === 'point' ? 'Signal' : type
                const label = `${parentLocationName} - ${areaName}`

                return {
                  typeLabel,
                  label,
                  type: key,
                  value,
                }
              }

              return {
                typeLabel: type,
                label: areaLabel,
                type: key,
                value,
              }
            }
            // NOTE: Area filters exist in multiple places under different query params names, so we have to support multiple versions of the key
            if (key === 'location' || key === 'locations') {
              const area = get(caches, `areas.options.${documentId}.entity`, {})
              const { _id: value, type = 'location' } = area
              const isLocationGroupArea = type === 'location-group'
              const refVal = area?.reference || ''
              const sin = area?.plugins?.timegate?.options?.SiteSIN
              const jobNumber = area?.plugins?.winteam?.options?.jobNumber
              const ref = sin
                ? `SIN: ${sin}`
                : jobNumber
                ? `Job #${jobNumber}`
                : `Ref: #${refVal}`
              const hasRef = sin || jobNumber || refVal

              const unknownAreaLabel = isLocationGroupArea
                ? t('labelUnknownLocationGroup')
                : t('labelUnknownArea')
              const name = area?.name || unknownAreaLabel
              const areaName = truncate(name, { length: 24 })
              const areaLabel = hasRef ? `${areaName}, ${ref}` : areaName
              return {
                typeLabel: type,
                label: areaLabel,
                type: key,
                value,
              }
            }

            if (key === 'groups') {
              const area = get(caches, `areas.options.${documentId}.entity`, {})
              const { _id: value, name = t('labelUnknownLocationGroup') } = area
              const areaName = truncate(name, { length: 24 })
              return {
                typeLabel: t('labelLocationGroup'),
                label: areaName,
                type: key,
                value,
              }
            }

            // This works for schedule types and event types.
            // Both have diferent options.
            if (key === 'type') {
              const { label, type, value } = get(
                caches,
                `${key}s.options.${documentId}`
              )

              const isEventType = label === 'Enter'
              const isScheduleType = label === 'Type'

              const adjustedTypeLabel = isScheduleType
                ? t('labelType')
                : isEventType
                ? t('labelEvent')
                : typeLabel

              return {
                typeLabel: adjustedTypeLabel,
                label,
                type,
                value,
              }
            }

            if (key === 'schedule.type') {
              const value = get(caches, `exceptionTypes.options.${documentId}`)
              return {
                typeLabel: t('labelType'),
                label: value,
                type: key,
                value: documentId,
              }
            }

            if (key === 'state') {
              const value = get(caches, `exceptionStates.options.${documentId}`)
              return {
                typeLabel: t('labelStatus'),
                label: value,
                type: key,
                value: documentId,
              }
            }

            if (key === 'audit') {
              const audit = get(caches, `${key}s.options.${documentId}`, {})
              const { title: label } = audit.entity

              return {
                typeLabel,
                label,
                type: key,
                value: documentId,
              }
            }

            if (key === 'task') {
              const template = get(
                caches,
                `templates.options.${documentId}`,
                {}
              )
              const { name: label } = template.entity

              return {
                typeLabel: t('labelTask'),
                label,
                type: key,
                value: documentId,
              }
            }

            if (key === 'status') {
              const formattedLabel = documentId
                .split(/-/g)
                .map(capitalize)
                .join('')

              const translatedLabel = t(`label${formattedLabel}`)
              return {
                typeLabel: t('labelStatus'),
                label: translatedLabel,
                type: key,
                value: documentId,
              }
            }

            if (key === 'assignees') {
              const assignee = find(caches.users.options, [
                'entity.user._id',
                documentId,
              ])
              const { fullName: label } = assignee.entity.user
              return {
                typeLabel: t('labelAssignee'),
                label,
                type: key,
                value: documentId,
              }
            }

            if (key === 'enabledState') {
              const selectedState = find(caches.enabledStates.options, {
                value: documentId,
              })
              const { label, labelT } = selectedState
              return {
                typeLabel: t('labelEnabled'),
                label: labelT ? t(labelT) : label,
                type: key,
                value: documentId,
              }
            }

            if (key === 'userStatus') {
              const label = t(`label${capitalize(documentId)}`)

              return {
                typeLabel: 'status',
                label,
                type: key,
                value: documentId,
              }
            }

            if (key === 'roleRestrictions') {
              const role = get(
                caches,
                `roleRestrictions.options.${documentId}`,
                {}
              )
              const { name: label } = role.entity

              return {
                typeLabel: t('labelRole'),
                label,
                type: key,
                value: documentId,
              }
            }

            return {
              typeLabel,
              label,
              type: key,
              value,
            }
          })
          return [...accum, tags]
        }

        return accum
      },
      []
    )

    return flattenDeep([reduced])
  }

  function onSelect(item): void {
    const { type, value } = item

    if (includes(selectedFilters[type], value)) {
      return onClearFilter(item)
    }

    onSetFilter(item)
  }

  const tags = buildTags(allCaches, selectedFilters)

  useEffect(() => {
    if (!tags.length) {
      setTagsVisibility(true)
    }

    const nextExpandIconVisible =
      !tagsVisible || filterBarRef.current.offsetHeight > ONE_LINE_HEIGHT
    setExpandIconVisibility(nextExpandIconVisible)
  }, [setExpandIconVisibility, setTagsVisibility, tags, tagsVisible])

  return (
    <Block backgroundColor={colors.gray.lightest} width="100%">
      <Flex
        justifyContent="space-between"
        width="100%"
        reference={filterBarRef}
      >
        <Block
          borderRight={`1px solid ${colors.gray.lighter}`}
          marginTop={!enableSearch && 50}
        >
          {enableSearch && (
            <FilterSearch
              dataTestId="search-field"
              onChange={handleSetSearch}
              placeholder={t('placeholder.search_ellipsis')}
              search={search}
              noBorder
            />
          )}
        </Block>
        {enableFilter ? (
          <Flex flexDirection="column">
            <MenuButton onClick={(): void => setVisibility(!visible)} />
            {visible && (
              // Note: The div is needed. Without this, the vertical offset
              // of the babushka dropdown is not correct
              <div>
                <Babushka
                  active={active}
                  categories={categories}
                  onSelect={onSelect}
                  position={0}
                  selectedItems={selectedFilters}
                  setActive={setActive}
                  setVisibility={setVisibility}
                />
              </div>
            )}
          </Flex>
        ) : null}
        {
          <Tags
            onClearAllFilters={onClearAllFilters}
            onSelect={onSelect}
            enableTags={tagsVisible}
            tags={tags}
          />
        }
        <ResultCount
          enableCount={enableCount}
          isResolving={loading}
          resultsCount={resultsCount}
          totalCount={totalCount}
        />
        {expandIconVisible && (
          <Button
            height={40}
            marginRight={0}
            marginTop={6}
            marginBottom={6}
            width={40}
            backgroundColor={colors.gray.lightest}
            onClick={() => setTagsVisibility(!tagsVisible)}
          >
            <Icon fontSize={20} fontWeight={400} lineHeight={1} name={icon} />
          </Button>
        )}
      </Flex>
    </Block>
  )
}
