import { chain, dropRight, get, includes, last, set } from 'lodash'
import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { List } from 'react-virtualized'
import { useTranslation } from 'react-i18next'

import { Category, Categories } from 'components/babushka'
import { Block, Flex, Text } from 'components/common'
import Chevron from 'components/chevron'
import Spinner from 'components/spinner'
import { colors } from 'config/theme'
import BabushkaItem, { Item } from '../babushka-item'
import styles from './styles'

interface Props {
  active: []
  categories: Categories
  onSelect: Function
  position: number
  selectedItems?: {
    key: string
    value: string
  }[]
  setActive: Function
  setVisibility: Function
}

const MAX_HEIGHT = 294
const ROW_HEIGHT = 42
const WIDTH = 275

function BabushkaRecursive(props: Props): JSX.Element {
  const { t } = useTranslation()
  const [items, setItems] = useState(null)
  // NOTE: enable loading state when babushka is fully api driven otherwise
  // flickering effect is introduced as data immediately available
  //const [itemsLoading, setItemsLoading] = useState(true)
  const [itemsLoading, setItemsLoading] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const {
    active,
    categories,
    onSelect,
    position,
    selectedItems,
    setActive,
    setVisibility,
  } = props

  const currentActive: { value: string } = active[position]
    ? last(active[position])
    : null

  const isTopCategory = get(categories, 'children.type') === 'Top'
  const categoryName = currentActive ? `${currentActive.value}` : ''
  const categoryChildren = isTopCategory
    ? `children.${categoryName}`
    : 'children.children'

  const hasItems = get(categories, 'children.getItems') || false
  const nestedCategory = currentActive
    ? get(categories, categoryChildren) || categories.children
    : null

  useEffect((): void => {
    setItemsLoading(true)
    if (hasItems) {
      const isMultiSelect = get(categories, 'multiSelect', true)
      const type = get(categories, 'children.type')
      const getItems = get(categories, 'children.getItems')
      async function fetchItems(): Promise<void> {
        // NOTE in future we might want to pass down more of the active items as
        // the sub item might need to filter by more than just the selected
        // option from the parent level
        const response = await getItems(type, active && last(last(active)))
        const items = isMultiSelect
          ? response
          : response.map((item): Item[] => {
              return { multiSelect: false, ...item }
            })
        setItems(items)
        setItemsLoading(false)
      }

      fetchItems()
    } else {
      const items = chain(categories.children)
        .map((category: Category, key): object => {
          if (typeof category !== 'object') return null
          const { label, selectable, type } = category
          return {
            label,
            selectable,
            type,
            value: key,
          }
        })
        .compact()
        .value()

      setItems(items)
      setItemsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onDrillDown = useCallback(
    (item): void => {
      const { label, value } = item
      if (active.length > 0) {
        const type = get(last(last(active)), 'label')
        return setActive([...active, [{ type, label, value }]])
      }

      return setActive([[{ type: 'type', label, value }]])
    },
    [active, setActive]
  )

  const memoItems = useMemo((): Item[] => {
    const regex = new RegExp(searchValue.trim(), 'i')
    return chain(items)
      .filter((item): boolean =>
        !searchValue
          ? true
          : item
          ? regex.test(item.search || item.label)
          : false
      )
      .sortBy(item => [item.typeLabel, item.label])
      .orderBy(item => [item.label.toLowerCase()], ['asc'])
      .map(
        (item): Item => {
          const { type, typeLabel } = item
          if (typeLabel === 'Zone') set(item, 'typeLabel', 'Signal')
          if (type === 'top') return
          if (get(categories, 'multiSelect', null) === null)
            return { ...item, multiSelect: true }
          return item
        }
      )
      .value()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, searchValue])

  function rowRenderer(rowRenderProps): JSX.Element {
    const { index, key, style } = rowRenderProps
    const { value, selectable = true, type } = memoItems[index]

    const selected =
      position !== 0 && type && includes(selectedItems[type], value)

    return (
      <BabushkaItem
        item={memoItems[index]}
        key={key}
        onDrillDown={onDrillDown}
        onSelect={selectable ? onSelect : undefined}
        selected={selected}
        styles={style}
      />
    )
  }

  const parentType = get(last(last(active)), 'type')
  const type = get(categories, 'label') || get(categories, 'type')
  const noResultsLabel = `No '${type}' records for this ${parentType}`
  const hasNoChildren = memoItems.length === 0
  const totalHeight = memoItems.length * ROW_HEIGHT
  const height = totalHeight > MAX_HEIGHT ? MAX_HEIGHT : totalHeight
  const showSearch = position !== 0 && position === active.length
  const nestedLoadingOffset = itemsLoading && position !== 0

  return (
    <Flex
      backgroundColor={colors.white}
      left={nestedLoadingOffset ? '45%' : 0}
      position="relative"
      flexShrink={0}
      width={WIDTH}
      zIndex={600}
    >
      {itemsLoading ? (
        <Flex
          alignItems="center"
          backgroundColor={colors.white}
          justifyContent="center"
          paddingBottom={20}
          paddingTop={20}
        >
          <Spinner size="medium" type="circle" />
        </Flex>
      ) : (
        <React.Fragment>
          <Block flexShrink={0} width="100%">
            {!itemsLoading && showSearch && (
              <Flex
                backgroundColor={colors.gray.lightest}
                border={`1px solid ${colors.gray.lighter}`}
                flex={1}
                height={42}
                outline="none"
                zIndex={800}
              >
                <Flex
                  alignItems="center"
                  borderRight={`1px solid ${colors.gray.lighter}`}
                  justifyContent="center"
                  onClick={(): void => {
                    setActive(dropRight(active))
                  }}
                  width={48}
                >
                  <Chevron left />
                </Flex>
                <input
                  autoFocus
                  data-testid="selectOrSearch-filter"
                  onChange={(e): void => setSearchValue(e.target.value)}
                  placeholder={t('placeholder.selectOrSearch')}
                  value={searchValue}
                  style={styles.searchInput}
                />
              </Flex>
            )}
            {!hasNoChildren && (
              <List
                height={height}
                rowCount={memoItems.length}
                rowHeight={ROW_HEIGHT}
                rowRenderer={rowRenderer}
                style={styles.menuList}
                width={WIDTH}
              />
            )}
            {hasNoChildren && (
              <Flex flex={1} outline="none" zIndex={800}>
                <Text medium textAlign="center" width={WIDTH}>
                  {noResultsLabel}
                </Text>
              </Flex>
            )}
          </Block>
        </React.Fragment>
      )}
      {nestedCategory && !hasNoChildren && (
        <BabushkaRecursive
          active={active}
          categories={nestedCategory}
          onSelect={onSelect}
          position={position + 1}
          selectedItems={selectedItems}
          setActive={setActive}
          setVisibility={setVisibility}
        />
      )}
    </Flex>
  )
}

export default BabushkaRecursive
