import {
  activeBeaconIcon,
  activeNfcIcon,
  activeQrIcon,
  auditIcon,
  duressIcon,
  issueIcon,
  unknownLocationIcon,
  taskIcon,
} from '../../../../routes/maps/lib/icons'

export const RESOURCE_ICON_MAP = {
  address: unknownLocationIcon,
  auditentries: auditIcon,
  duress: duressIcon,
  issue: issueIcon,
  issues: issueIcon,
  activeBeaconIcon: activeBeaconIcon,
  activeNfcIcon: activeNfcIcon,
  activeQrIcon: activeQrIcon,
  signal: activeBeaconIcon,
  taskentries: taskIcon,
}
